<template>
  <header class="header">
    <div class="header-content">
      <!-- LOGO -->
      <RouterLink
        v-if="isAuth"
        :to="{ name: ROUTE_LIST_NANNY_NAME }"
        style="display: flex"
      >
        <SvgLogoFull class="logo" />
      </RouterLink>
      <SvgLogoFull v-else class="logo" />
      <!-- END of LOGO -->

      <ButtonLink
        v-if="isAuth === false"
        :to="link.to"
        :label="link.label"
        :size="'small'"
      />
    </div>
  </header>
</template>

<script>
import useAuth from "@/composables/useAuth";

import SvgLogoFull from "@/components/Svg/Logo/SvgLogoFull.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";
import {
  ROUTE_LANDING_NANNY_CLIENT_NAME,
  ROUTE_LANDING_NANNY_CONTRACTOR_NAME,
  ROUTE_LIST_NANNY_NAME,
} from "@/router/constants";
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";

export default {
  components: {
    SvgLogoFull,
    ButtonLink,
  },

  setup() {
    const { isAuth } = useAuth();
    const userStore = useUserStore();
    const { userType } = storeToRefs(userStore);

    const link = computed(() => {
      const uType = userType.value;
      let name, label;

      if (uType === "client") {
        name = ROUTE_LANDING_NANNY_CONTRACTOR_NAME;
        label = "Jestem nianią";
      } else if (uType === "contractor") {
        name = ROUTE_LANDING_NANNY_CLIENT_NAME;
        label = "Szukam nianię";
      } else {
        throw new Error(`Wrong userType: ${uType}`);
      }

      return {
        to: {
          name,
        },
        label,
      };
    });

    return {
      isAuth,
      link,
      ROUTE_LIST_NANNY_NAME,
    };
  },
};
</script>

<style scoped>
.header {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-content {
  width: var(--header-default--width);
  height: var(--header-default--height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .logo {
  width: 86px;
}
</style>
